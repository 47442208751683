.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}

.ant-select {
  font-size: 16px;
}

.ant-select-arrow {
  top: 30%;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  right: 0;
  left: 0;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  font-size: 16px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.87);
  border-right-width: 1px !important;
  border-width: 2px;
}

.clIhSf .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #000066 !important;
  border-width: 2px !important;
  border-right-width: none;
  box-shadow: none;
}

.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-select-clear {
  right: 38px !important;
  top: 28% !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
